/* src/index.css */

*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

p {
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: black;
  word-break: break-all;
}

.link--to--project {
  word-break: normal;
}

header {
  background-color: #f5f5f5;
  color: black;
  text-align: center;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 70px;
  gap: 2rem;
  padding-right: 2rem;
  border-bottom: 1px solid black;
}

.header--name {
  font-size: 2rem;
  font-family: "Abel";
}

.header--contact {
  width: 70px;
  height: 70px;
  background-color: black;
  color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

header--contact--svg {
  width: 30px;
}

.header--year {
  margin-left: auto;
  font-family: "Abel";
}

/* ========

NAV

======== */

nav {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: start;
  border-bottom: 1px solid black;
}
nav img {
  width: 10px;
  transition: all 0.3s;
}

.nav--open-menu {
  width: 70px;
  height: 70px;
  border-right: 1px solid black;
}

button {
  cursor: pointer;
}

.button--filter {
  width: 70px;
  height: 70px;
  border: none;
  border-right: 1px solid black;
  outline: 0px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav img:hover {
  scale: 1.2;
  transition: all 0.2s;
}

.button--filter:hover {
  background-color: rgb(0, 94, 255);
  color: white;
}

.button-text {
  display: none;
  position: absolute;
  top: 90px;
  left: 510px;
  color: black;

  font-size: 1.5rem;
  font-family: "Abel", sans-serif;
}

.button--filter:hover .button-text {
  display: block;
}

.social--link {
  display: flex;
  justify-content: start;
}

.instagram img,
.email img {
  width: 25px;
}

.instagram {
  justify-self: end;
  border-left: 1px solid black;
}

/* ==========

HOME

========== */

.home--container {
  display: grid;
  grid-template-columns: 70px 1fr;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px); /* Pantalla completa */
  overflow: hidden;
}

.home--container--aside {
  border-right: 1px solid black;
  width: 70px;
  height: calc(100vh);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.home--container--image {
  position: relative; /* Necesario para que la imagen pequeña se superponga */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: auto;
}

.home-image-large {
  width: 500px; /* Imagen grande */
  height: auto;
  position: relative;
  z-index: 1;
}

.home-image-small {
  width: 250px; /* Imagen pequeña */
  height: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;

  z-index: 2; /* La imagen pequeña estará delante */
}

/* ==========

BANNER TEXT

========== */

.banner--text {
  position: absolute;
  writing-mode: vertical-rl; /* Rotar el texto verticalmente */
  text-orientation: mixed;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: yellow;
  text-decoration-thickness: 5px;
  text-underline-offset: 0.5rem;
  color: blue; /* Cambia esto según el diseño que prefieras */
  animation: scrollText 50s linear infinite; /* Aplica la animación */
  rotate: 180deg;
}

/* Animación para el texto en loop vertical */
@keyframes scrollText {
  0% {
    transform: translateY(110%); /* Comienza fuera de la vista desde abajo */
  }
  100% {
    transform: translateY(-110%); /* Desplaza hacia arriba fuera de la vista */
  }
}

.rotating-text {
  white-space: nowrap;
  letter-spacing: 0.2rem;
}

/* ==========

PROYECTOS GRID

========== */

.grid--proyectos {
  display: grid;
  grid-template-columns: 1fr;
  overflow: scroll;
}

.grid--proyecto--card {
  border-bottom: 1px solid black;
  display: grid;
  grid-template-columns: 70px 1fr 70px;
  align-items: start;
  justify-content: start;
  gap: 2rem;
}

.grid--proyecto--card--title {
  font-size: 3rem;
  padding-block: 2rem;
  font-family: "Abel", serif;
  line-height: 3rem;
  position: relative;
  display: inline-block;
}

.grid--proyecto--card--title::before {
  content: "";
  background-color: yellow;
  width: 0%;
  position: absolute;
  left: 0px;
  bottom: 20px;
  height: 10px;
  transition: all 0.4s;
}

.grid--proyecto--card--title:hover::before {
  width: 100%;

  transition: all 0.7s;
}

.grid--proyecto--card--icon {
  overflow: hidden;
  border-right: 1px solid black;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 2.4rem;
}

.grid--proyecto--card--icon img {
  width: 30px;
  position: relative;
  display: block;
}

.grid--proyecto--card--year {
  padding-top: 2.4rem;
}

.cerrar--filtro {
  width: 100%;
  height: 100%;
  border: 0px;
}

.wrapper {
  height: calc(100vh - 140px);
  display: grid;
  grid-template-columns: 1fr;
}

.wrapper--aside {
  border-right: 1px solid black;
}

/* ====== 
PROYECTO
 ======== */

.proyecto {
  display: grid;
  grid-template-columns: 70px 1fr;
  height: calc(100vh - 140px);
  overflow: hidden;
}
.proyecto--aside {
  border-right: 1px solid black;
  height: 100%;
}

.proyecto--cover,
.proyecto--img {
  max-width: 600px;
  margin-bottom: 4rem;
}

.proyecto--parrafos {
  margin-bottom: 4rem;
  max-width: 800px;
}
.proyecto--parrafos ul {
  list-style: none;
  margin-top: 1rem;
}
.proyecto--parrafos ul li {
  margin-bottom: 1rem;
}

.proyecto--parrafos a {
  background-color: rgba(153, 226, 202, 0.463);
  text-decoration: underline;
  text-decoration-color: yellow;
  text-decoration-thickness: 4px;
}

.proyecto--parrafos--prensa {
  margin-top: 4rem;
}

.proyecto--parrafos h3 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.proyecto--content {
  overflow: scroll;
  padding-top: 4rem;
  padding-inline-start: 70px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  font-family: "Abel", serif;
}

.proyecto--content h1 {
  font-size: max(5vw, 4rem);
  line-height: max(5vw, 4rem);
  text-decoration: underline;
  text-decoration-color: yellow;
  text-decoration-thickness: 10px;
  margin-bottom: 1rem;
}

.proyecto--content--subtitle {
  text-transform: uppercase;
  letter-spacing: 1rem;
  font-weight: 100;
  font-size: 0.7rem;
  margin-bottom: 4rem;
}

/* =========

POP-UP

==========*/

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 10px 10px yellow;
}

.popup {
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  max-width: 900px;
  width: 100%;
  box-shadow: 5px 5px yellow, 10px 10px blue;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup h2 {
  margin-top: 0;
}

.close-popup {
  margin-top: 20px;
  padding: 10px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 0;
  border: 1px solid black;
}

.close-popup:hover {
  background-color: #333;
}

.popup--social ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  list-style: none;
  margin-top: 2rem;
}

.popup-form-btn {
  flex: 1;
  border: 1px solid black;
  background-color: white;
  padding-block: 0.8rem;
}

@media only screen and (max-width: 850px) {
  img {
    width: 100%;
  }

  .header--name {
    font-size: 1rem;
  }
  .header--year {
    font-size: 1rem;
  }

  .proyecto {
    display: grid;
    grid-template-columns: 1fr;
    height: calc(100vh - 140px);
    overflow: hidden;
  }
  .proyecto--aside {
    display: none;
  }

  .proyecto--content {
    overflow: scroll;
    padding-top: 4rem;
    display: block;
    font-family: "Abel", serif;
    padding-inline-start: 0px;
    padding-inline: 1.5rem;
  }

  .home--container {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 140px); /* Pantalla completa */
    overflow: hidden;
    width: 100%;
  }

  .home--container--aside {
    border-bottom: 1px solid black;
    border-right: 0px solid black;
    width: 100%;
    height: 70px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .grid--proyecto--card--year {
    display: none;
  }

  .proyecto--content h1 {
    font-size: 3rem;
  }

  .home--container--image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 210px);
  }

  .button--filter:hover .button-text {
    display: none;
  }

  .banner--text {
    position: absolute;
    writing-mode: horizontal-tb; /* Rotar el texto verticalmente */
    text-orientation: mixed;
    font-size: 1rem;
    font-weight: bold;
    color: blue; /* Cambia esto según el diseño que prefieras */
    animation: scrollText 50s linear infinite; /* Aplica la animación */
    rotate: 0deg;
  }

  /* Animación para el texto en loop vertical */
  @keyframes scrollText {
    0% {
      transform: translateX (110%); /* Comienza fuera de la vista desde abajo */
    }
    100% {
      transform: translateX(
        -110%
      ); /* Desplaza hacia arriba fuera de la vista */
    }
  }
}

@media only screen and (max-width: 630px) {
  .instagram {
    justify-self: end;
    border-left: none;
    width: 70px;
  }
}
